import { useAuth0 } from "@auth0/auth0-react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as React from "react";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { addAppointmentCompleted, addAppointmentResult, getAppointment, getOrder, updateAppointmentResult } from "../api/orderportal_apimanager";
import { AppointmentResult, AppointmentType, ResultProduct } from "../api/types/AppointmentType";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import { CreateLeaktestRaport } from "../modules/pdf/createLeaktestRaport";
import toastManager from "../modules/toastmanager";
import { Order } from "../api/types/Order";
import { useTenant } from "../hooks/retrieveHooks";

export default function Appointment() {
	const { isAuthenticated, user } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const tenantID = localStorage.getItem("tenant");

	const queryParameters = new URLSearchParams(window.location.search);
	const appointmentID = queryParameters.get("id");
	const [reload, setReload] = useState<boolean>(true);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showCheckuo, setShowCheckup] = useState<boolean>(false);
	const [remarkRequired, setRemarkRequred] = useState<boolean>(false);

	const [linkedSalesOrder, setLinkedSalesOrder] = useState<Order>();

	const [appointment, setAppointment] = useState<AppointmentType>();

	const [selectedProduct, setSelectedProduct] = useState<ResultProduct>();

	const handleCloseModal = () => setShowModal(false);
	const handleOpenModal = () => setShowModal(true);

	const handleCloseCheckup = () => {
		setShowCheckup(false);
		setSelectedProduct(undefined);
	};
	const handleOpenCheckup = (product: ResultProduct) => {
		setShowCheckup(true);
		setSelectedProduct(product);
	};

	function handleRegisterImpression(event: any) {
		const etf = event.target.form;
		setRemarkRequred(!etf.left_seal_ok.checked || !etf.left_fit_ok.checked || !etf.right_seal_ok.checked || !etf.right_fit_ok.checked);
		const remarks = etf.remarks.value;
		if (selectedProduct && appointment && tenantID) {
			// Currently adds a appointment per product
			const results: AppointmentResult = {
				product: selectedProduct.id,
				right_fit: etf.right_fit_ok.checked,
				left_fit: etf.left_fit_ok.checked,
				right_seal: etf.right_seal_ok.checked,
				left_seal: etf.left_seal_ok.checked,
				fit_seal_remarks: remarks,
				rework_data: {
					remodel_left: etf.remodel_left?.checked,
					remodel_right: etf.remodel_right?.checked,
					reprint_left: etf.reprint_left?.checked,
					reprint_right: etf.reprint_right?.checked,
					rescan_left: etf.rescan_left?.checked,
					rescan_right: etf.rescan_right?.checked,
				},
			};
			const inResults = appointment?.results.find((e) => e.product === selectedProduct?.id);
			if (inResults && inResults.id)
				updateAppointmentResult(tenantID, appointment.id, results, inResults.id).then((response) => {
					if (response) {
						if (!response.right_fit || !response.right_seal || !response.left_fit || !response.left_seal)
							toastManager("error", `Failed ${appointment.type}`, `The product cannot be safely used by ${selectedProduct.consumer_name}`);
						else
							toastManager(
								"success",
								`Successfully completed ${appointment.type}`,
								`${selectedProduct.consumer_name} can ${appointment.type === "handoff" ? "now" : "still"} safely use their earbuds`
							);
						setReload(true);
						handleCloseCheckup();
					}
				});
			else
				addAppointmentResult(tenantID, appointment.id, results).then((response) => {
					if (response) {
						if (!response.right_fit || !response.right_seal || !response.left_fit || !response.left_seal)
							toastManager("error", `Failed ${appointment.type}`, `The product cannot be safely used by ${selectedProduct.consumer_name}`);
						else
							toastManager(
								"success",
								`Successfully completed ${appointment.type}`,
								`${selectedProduct.consumer_name} can ${appointment.type === "handoff" ? "now" : "still"} safely use their earbuds`
							);
						setReload(true);
						handleCloseCheckup();
					}
				});
		}
	}

	function PopulateAppointmentDetails() {
		var temp_array: JSX.Element[] = [];
		if (appointment?.products) {
			var products: ResultProduct[] = appointment?.products as ResultProduct[];
			products.forEach((product) => {
				const inResults = appointment.results.find((e) => e.product === product.id);
				temp_array.push(
					<ListGroup.Item
						style={{ cursor: "pointer" }}
						key={product.id}
						action={!inResults}
						variant={!inResults?.left_fit || !inResults?.right_fit || !inResults?.right_seal || !inResults?.left_seal ? (!inResults ? "none" : "danger") : "success"}
						onClick={() => handleOpenCheckup(product)}
					>
						<Row>
							<Col>{product.consumer_name}</Col>
							<Col>{product.production_order}</Col>
							<Col>{product.serial_cradle}</Col>
							<Col>{product.serial_left}</Col>
							<Col>{product.serial_right}</Col>
							{inResults ? <Col>{new Date(inResults!.stamp as number).toLocaleDateString()}</Col> : <Col></Col>}
						</Row>
					</ListGroup.Item>
				);
			});
		}
		return <>{temp_array}</>;
	}

	function amountOfFailedProducts(): number {
		var failedCount = 0;
		appointment?.results.forEach((result) => {
			if (!result.left_fit || !result.left_seal || !result.right_fit || !result.right_seal) failedCount++;
		});
		return failedCount;
	}

	function handleConfirmation(event: any) {
		if (tenantID && appointment?.id)
			addAppointmentCompleted(token, tenantID, appointment?.id).then((response) => {
				if (response) {
					var completedDate = new Date(0);
					completedDate.setMilliseconds(response.completed);
					toastManager("success", `${appointment.type} completed `, `Completed ${appointment.type} at ${completedDate.toLocaleDateString()}`);
					navigate(-1); // navigate back to the appointments screen
				}
			});
	}

	function defaultCheckForResults(
		type: "left_seal" | "left_fit" | "right_seal" | "right_fit" | "rescan_left" | "rescan_right" | "remodel_left" | "remodel_right" | "reprint_left" | "reprint_right"
	) {
		const inResults = appointment?.results.find((e) => e.product === selectedProduct?.id);
		if (!inResults) return undefined; // return if no action has been done on product
		if (inResults) {
			if (inResults.left_seal && type === "left_seal") return true;
			if (inResults.left_fit && type === "left_fit") return true;
			if (inResults.right_seal && type === "right_seal") return true;
			if (inResults.right_fit && type === "right_fit") return true;
			if (inResults?.rework_data?.remodel_left && type === "remodel_left") return true;
			if (inResults?.rework_data?.remodel_right && type === "remodel_right") return true;
			if (inResults?.rework_data?.rescan_left && type === "rescan_left") return true;
			if (inResults?.rework_data?.rescan_right && type === "rescan_right") return true;
			if (inResults?.rework_data?.reprint_left && type === "reprint_left") return true;
			if (inResults?.rework_data?.reprint_right && type === "reprint_right") return true;
			else return false;
		}
	}

	useEffect(
		function getAppointmentFromAPI() {
			if (appointmentID && token && token.length && tenantID && reload)
				getAppointment(token, tenantID, appointmentID).then((response) => {
					if (response) {
						setReload(false);
						setAppointment(response);
					}
				});
		},
		[appointmentID, reload, tenantID, token]
	);

	useEffect(
		function getOrderIfHandoff() {
			if (appointment && appointment?.type === "handoff" && appointment.linked_salesorder) {
				getOrder(token, appointment.linked_salesorder).then((response) => {
					if (response) {
						setLinkedSalesOrder(response);
					}
				});
			}
		},
		[appointment, token]
	);

	return (
		<>
			<Navigation />

			{isAuthenticated && (
				<Container className="paddingTopBottom">
					<BackButton
						route={`/appointments`}
						title="Appointments"
					/>
					<Row className="center">
						<Col>
							<h4>{appointment?.customer_name}</h4>
							<h1 className="text-capitalize">{appointment?.type} </h1>
						</Col>
					</Row>
					<hr />
					{linkedSalesOrder && (
						<>
							<Row>
								<Col>
									Exact ID: {linkedSalesOrder?.exact_order_number}
									<br />
									Reference: {linkedSalesOrder?.referenceId}
								</Col>
							</Row>
							<hr />
						</>
					)}
					<ListGroup>
						<ListGroup.Item
							className="fw-bold"
							variant="secondary"
						>
							<Row>
								<Col>Name</Col>
								<Col>PR number</Col>
								<Col>Cradle serial</Col>
								<Col>Left serial</Col>
								<Col>Right serial</Col>
								<Col>Completed date</Col>
							</Row>
						</ListGroup.Item>
						<PopulateAppointmentDetails />
					</ListGroup>
					<br />
					{amountOfFailedProducts() >= 1 && !appointment?.completed && (
						<Alert
							className="center"
							variant="warning"
						>
							<Alert.Heading>
								<Icon.ExclamationLg />
								{amountOfFailedProducts()} product(s) did not pass the {appointment?.type} <Icon.ExclamationLg />
							</Alert.Heading>
							By completing this appointment reworks will be made for these products. <br /> <b>There could be payment requirements by completing this order.</b>
						</Alert>
					)}
					{!appointment?.completed && (
						<Button
							className="right"
							onClick={handleConfirmation}
						>
							<Icon.Check />
							Complete appointment
						</Button>
					)}

					<Modal
						size="xl"
						show={showModal}
						onHide={handleCloseModal}
					>
						<Modal.Header closeButton>
							<h2>How to check seal & fit</h2>
						</Modal.Header>
						<Modal.Body>
							<object
								className="pdf-modal"
								data="/Voorwaarden_voor_Dopple_Protect_input_model.pdf"
								type="application/pdf"
								width="100%"
								height="100%"
							>
								<p>Open this page on desktop to view the PDF!</p>
							</object>
						</Modal.Body>
					</Modal>

					<Modal
						size="xl"
						show={showCheckuo}
						onHide={handleCloseCheckup}
					>
						<Modal.Header closeButton>
							<h2>Checklist for {selectedProduct?.consumer_name}</h2>
						</Modal.Header>
						<Modal.Body>
							<Container>
								<Form>
									<Row>
										<Col>
											<h4 className="fw-bold">Left earshell</h4>
											<Row>
												<Col>
													<h4>Seal</h4> <br />
													<Form.Group>
														<Form.Check
															name="left_seal"
															id="left_seal_ok"
															type="radio"
															inline
															label="Good seal"
															defaultChecked={defaultCheckForResults("left_seal")}
														/>
														<Form.Check
															name="left_seal"
															id="left_seal_returned"
															type="radio"
															inline
															label="Bad seal, send back*"
															defaultChecked={defaultCheckForResults("left_seal") === false}
														/>
													</Form.Group>
												</Col>
												<Col>
													<h4>Fit</h4> <br />
													<Form.Group>
														<Form.Check
															name="left_fit"
															id="left_fit_ok"
															type="radio"
															inline
															label="Good fit"
															defaultChecked={defaultCheckForResults("left_fit")}
														/>

														<Form.Check
															name="left_fit"
															id="left_fit_returned"
															type="radio"
															inline
															label="Bad fit, send back*"
															defaultChecked={defaultCheckForResults("left_fit") === false}
														/>
													</Form.Group>
												</Col>
											</Row>
										</Col>

										<Col>
											<h4 className="fw-bold">Right earshell</h4>
											<Row>
												<Col>
													<h4>Seal</h4> <br />
													<Form.Group>
														<Form.Check
															name="right_seal"
															id="right_seal_ok"
															type="radio"
															inline
															label="Good seal"
															defaultChecked={defaultCheckForResults("right_seal")}
														/>

														<Form.Check
															name="right_seal"
															id="right_seal_returned"
															type="radio"
															inline
															label="Bad seal, send back*"
															defaultChecked={defaultCheckForResults("right_seal") === false}
														/>
													</Form.Group>
												</Col>
												<Col>
													<h4>Fit</h4> <br />
													<Form.Group>
														<Form.Check
															name="right_fit"
															id="right_fit_ok"
															type="radio"
															inline
															label="Good fit"
															defaultChecked={defaultCheckForResults("right_fit")}
														/>

														<Form.Check
															name="right_fit"
															id="right_fit_returned"
															type="radio"
															inline
															label="Bad fit, send back*"
															defaultChecked={defaultCheckForResults("right_fit") === false}
														/>
													</Form.Group>
												</Col>
											</Row>
										</Col>
										<hr />
										<small>* A remark is required if selected</small>
									</Row>
									<br />
									<Row>
										<Col>
											<h4>Remarks</h4>
											<Form.Control
												required={remarkRequired}
												as="textarea"
												id="remarks"
												rows={2}
												defaultValue={appointment?.results.find((e) => e.product === selectedProduct?.id)?.fit_seal_remarks ?? ""}
												placeholder="Leave a remark if anything came up during the appointment"
											/>
										</Col>
										<Form.Label>What to do in case of bad seal/fit</Form.Label>
										<Row>
											<Col>
												<Form.Check
													label="Rescan Left"
													name="left"
													id="rescan_left"
													type="radio"
													defaultChecked={defaultCheckForResults("rescan_left")}
												/>
											</Col>
											<Col>
												<Form.Check
													label="Rescan Right"
													name="right"
													id="rescan_right"
													type="radio"
													defaultChecked={defaultCheckForResults("rescan_right")}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<Form.Check
													label="Remodel Left"
													name="left"
													id="remodel_left"
													type="radio"
													defaultChecked={defaultCheckForResults("remodel_left")}
												/>
											</Col>
											<Col>
												<Form.Check
													label="Remodel Right"
													name="right"
													id="remodel_right"
													type="radio"
													defaultChecked={defaultCheckForResults("remodel_right")}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<Form.Check
													label="Reprint Left"
													name="left"
													id="reprint_left"
													type="radio"
													defaultChecked={defaultCheckForResults("reprint_left")}
												/>
											</Col>
											<Col>
												<Form.Check
													label="Reprint Right"
													name="right"
													id="reprint_right"
													type="radio"
													defaultChecked={defaultCheckForResults("reprint_right")}
												/>
											</Col>
										</Row>
									</Row>
									<br />
									{appointment && selectedProduct && appointment.completed && user && (
										<div className="download-pdf-button">
											<PDFDownloadLink
												document={
													<CreateLeaktestRaport
														appointment={appointment}
														result={appointment.results.find((e) => e.product === selectedProduct.id)}
														selectedProduct={selectedProduct}
														currentPortalUser={`${user.name}`}
													/>
												}
												fileName={`LEAKTEST_${selectedProduct?.consumer_name}.pdf`}
											>
												{/* @ts-ignore */}
												{({ blob, url, loading, error }) => (loading ? "Loading.." : <Icon.Download />)}
											</PDFDownloadLink>
										</div>
									)}
									<Button
										variant="primary"
										className="right"
										onClick={handleRegisterImpression}
									>
										{" "}
										<Icon.Check />
										Confirm
									</Button>
								</Form>
							</Container>
						</Modal.Body>
					</Modal>
				</Container>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
