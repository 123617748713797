import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Navigation from "../modules/navigation";
import BackButton from "../modules/backbutton";
import NotSignedIn from "../modules/notsignedin";
import { AppointmentType } from "../api/types/AppointmentType";
import { getAppointments, getTenant } from "../api/orderportal_apimanager";
import { Tenant } from "../api/types/Tenant";

export default function Appointments() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const tenantID = localStorage.getItem("tenant");

	const [tenant, setTenant] = useState<Tenant>();
	const [appointments, setAppointments] = useState<AppointmentType[]>();

	useEffect(
		function RetrieveAppointments() {
			if (token && token.length && tenantID)
				getAppointments(token, tenantID).then((response) => {
					if (response) {
						setAppointments(response);
					}
				});
		},
		[tenantID, token]
	);
	useEffect(
		function getTenantFromAPI() {
			if (token && token.length && tenantID)
				getTenant(token, tenantID).then((response) => {
					if (response) setTenant(response);
				});
		},
		[tenantID, token]
	);

	function PopulateAppointments() {
		var populatedAppointments: JSX.Element[] = [];
		var completedAppointments: JSX.Element[] = [];
		if (appointments) {
			appointments.forEach((appointment: AppointmentType) => {
				var plannedDate = new Date(0);
				plannedDate.setMilliseconds(appointment.planned);
				if (appointment.completed) {
					var completed = new Date(0);
					completed.setMilliseconds(appointment.completed);
					completedAppointments.push(
						<div key={appointment.id}>
							<ListGroup.Item
								action
								onClick={() => navigate(`appointment?id=${appointment.id}`)}
								variant={appointment.containsFailure ? "warning" : "success"}
							>
								<Row>
									<Col>{appointment.customer_name}</Col>
									<Col>{plannedDate.toLocaleDateString()}*</Col>
									<Col>{appointment.products?.length ?? "none"}</Col>
									<Col>{appointment.results.length}</Col>
									<Col className="text-capitalize">
										<small>{appointment.type} </small> {completed.toLocaleDateString()}
									</Col>
								</Row>
							</ListGroup.Item>
						</div>
					);
				} else {
					populatedAppointments.push(
						<div key={appointment.id}>
							<ListGroup.Item
								action
								onClick={() => navigate(`appointment?id=${appointment.id}`)}
								variant={appointment.products.length === appointment.results.length ? "info" : ""}
							>
								<Row>
									<Col>{appointment.customer_name}</Col>
									<Col>{plannedDate.toLocaleDateString()}*</Col>
									<Col>{appointment.products?.length ?? "none"}</Col>
									<Col>{appointment.results.length}</Col>
									<Col className="text-capitalize">{appointment.type}</Col>
								</Row>
							</ListGroup.Item>
						</div>
					);
				}
			});
		}
		return (
			<>
				{populatedAppointments} {completedAppointments}
			</>
		);
	}

	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<Container className="paddingTopBottom">
					<BackButton
						route={`/home`}
						title="Home"
					/>
					<Row className="center">
						<Col>
							<h4>{tenant?.name}</h4>
							<h1>Appointments</h1>
						</Col>
					</Row>
					<ListGroup>
						<ListGroup.Item
							className="fw-bold"
							variant="secondary"
						>
							<Row>
								<Col>Customer</Col>
								<Col>Planned date</Col>
								<Col>Products</Col>
								<Col>Done</Col>
								<Col>Type / Completed</Col>
							</Row>
						</ListGroup.Item>
						<PopulateAppointments />
					</ListGroup>
				</Container>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
