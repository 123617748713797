import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import { confirmOrder, getAdminOrders, getCustomers, getTenants } from "../api/orderportal_apimanager";
import { Customer } from "../api/types/Customer";
import { Order } from "../api/types/Order";
import { Tenant } from "../api/types/Tenant";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import toastManager from "../modules/toastmanager";
import { useNavigate } from "react-router-dom";
import NotSignedIn from "../modules/notsignedin";
import * as Icon from "react-bootstrap-icons";

export default function Production() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const [receivedCustomers, setReceivedCustomers] = useState(false);
	const [receivedAllOrders, setReceivedAllOrders] = useState(false);
	const [orders, setOrders] = useState<Order[]>([]);
	const [customers, setCustomers] = useState<Customer[]>([]);
	const [tenants, setTenants] = useState<Tenant[]>();

	function handleConfirmOrder(order: Order) {
		confirmOrder(order.id).then((response) => {
			if (response) {
				toastManager("success", "Confirmed order", `Confirmed order with reference id: '${order.referenceId}'`);
				setReceivedAllOrders(false);
			}
		});
	}

	function PopulateLockedOrders() {
		var temp: JSX.Element[] | undefined = [];
		if (tenants)
			orders.forEach((order) => {
				customers.forEach((customer) => {
					var tenantName = tenants.find((t) => t.id === customer.tenant_id)?.name;
					if (customer.id === order.customer_id) {
						temp?.push(
							<div key={order.id}>
								<ListGroup.Item variant={order.missing.length !== 0 ? "warning": "success"}>
									<Row>
										<Col sm={2}>{tenantName}</Col>
										<Col>{customer.name}</Col>
										<Col>
											<em>{order.referenceId}</em>
										</Col>
										<Col sm={2}>{order.exact_order_number}</Col>
										<Col>
											<Button disabled={order.missing.length !== 0} onClick={() => handleConfirmOrder(order)}>Confirm</Button>
										</Col>
									</Row>
									{order.missing && (
										<Row>
											<small className="fw-bold">
												{order.missing.map((missing) => (
													<>{missing.consumer}, </>
												))}
											</small>
										</Row>
									)}
								</ListGroup.Item>
							</div>
						);
					}
				});
			});

		return (
			<ListGroup
				key="keys"
				variant="flush"
				className="no-right-padding"
			>
				<ListGroup.Item>
					<Row className="fw-bold">
						<Col sm={2}>Tenant</Col>
						<Col>Customer</Col>
						<Col>Reference ID</Col>
						<Col sm={2}>Exact order number</Col>
						<Col>Confirm order</Col>
					</Row>
				</ListGroup.Item>
				{temp}
			</ListGroup>
		);
	}

	useEffect(
		function RetrieveTenants() {
			if (token && token.length)
				getTenants(token).then((response) => {
					if (response) setTenants(response);
				});
		},
		[token]
	);

	useEffect(
		function RetrieveCustomers() {
			if (!receivedCustomers && token && token.length) {
				getCustomers(token)
					.then((response) => {
						if (response !== undefined) {
							setCustomers(response);
							setReceivedCustomers(true);
						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		},
		[receivedCustomers, token]
	);

	useEffect(
		function RetrieveOrders() {
			if (!receivedAllOrders && token && token.length) {
				getAdminOrders(token).then((response) => {
					if (response) {
						setOrders(response);
						setReceivedAllOrders(true);
					}
				});
			}
		},
		[receivedAllOrders, token]
	);

	return (
		<>
			<Navigation />

			{isAuthenticated && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route="/home"
							title="homepage"
						/>
						<h1>Production</h1>
						<Alert
							className="center"
							variant="warning"
						>
							{" "}
							<Icon.ExclamationLg /> Make sure all production orders have scan and model data, if not an order cannot be confirmed <Icon.ExclamationLg />
						</Alert>
						<PopulateLockedOrders />
						<br />
						<Row className="gy-4 admin"></Row>
					</Container>
				</>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
